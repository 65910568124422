import { createMuiTheme } from '@material-ui/core/styles'

export const primaryColor = '#e31e73'
export const blackOff = '#202124'
export const white = '#FFFFFF'

export const typography = {
  fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
  lineHeight: 1.4,
}

export const headingTypography = {
  ...typography,
  lineHeight: 1.2,
  fontWeight: 700,
}

export const spacingUnit = 8

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 880,
      lg: 1160,
      xl: 1441,
    },
  },
  palette: {
    primary: {
      main: primaryColor,
      dark: primaryColor,
      light: primaryColor,
      contrastText: white,
    },
    secondary: {
      main: blackOff,
      contrastText: white,
    },
    text: {
      primary: blackOff,
    },
  },
  typography: {
    ...typography,
    h1: headingTypography,
    h2: headingTypography,
    h3: headingTypography,
    h4: headingTypography,
    h5: headingTypography,
    body1: typography,
    body2: typography,
  },
  overrides: {
    MuiButton: {
      root: {
        minHeight: 40,
        borderRadius: 99,
        textTransform: 'none',
      },
      sizeLarge: {
        minHeight: 48,
        borderRadius: 24,
      },
      label: {
        fontWeight: 700,
      },
      outlined: {
        borderColor: blackOff,
      },
    },
    MuiLink: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'white',
      },
    },
  },
  spacing: spacingUnit,
})

export default theme
