import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';

import '../../src/styles/index.css'

import theme from '../../src/styles/theme';

import { recaptchaSiteKey } from '../../src/config'

const scripts = [
  `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`,
  'https://www.gstatic.com/firebasejs/8.5.0/firebase-app.js',
  'https://www.gstatic.com/firebasejs/8.5.0/firebase-firestore.js',
]

export default function TopLayout(props) {
  useEffect(() => {
    scripts.forEach(src => {
      const script = document.createElement('script')
      script.src = src
      document.body.append(script)
    })
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta name="theme-color" content="#ffffff"></meta>

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest" />

        <title>Fil & Müsli</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </React.Fragment>
  );
}

TopLayout.propTypes = {
  children: PropTypes.node,
};
